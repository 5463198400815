import http from "@/http";
import eventBus from "@/events";
import Token from "@/token";

const Project = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'title') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const endpoint = Token.hasPermission('PROJECT_LIST_ALL_PERMISSION') ? '/projects/search/all' : '/users/search/userProjects';
            const resp = await http(endpoint, {params});
            eventBus.$emit('LIST_COUNT_PROJECT_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.projects;
        } else {
            const resp = await http("/projects/search/all");
            eventBus.$emit('LIST_COUNT_PROJECT_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.projects;
        }
    },
    create: function(data) {
        return http.post('/projects', data);
    },
    update: function (id, data) {
        return http.patch('/projects/' + id, data);
    },
    findById(id) {
        return http.get('/projects/' + id);
    },
    enable(id) {
        return http.patch('/projects/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/projects/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/projects/' + id)
    },
    async getDonors(id) {
        const resp = await http.get('/projects/' + id + '/companies');
        return resp.data._embedded.companies;
    },
    findProjectOrganizations: function(id) { return http.get('/projects/{id}/organizations'.replace('{id}', id)); },
    findProjectCompanies: function(id) { return http.get('/projects/{id}/companies'.replace('{id}', id)); },
    findProjectUsers: function(id) { return http.get('/projects/{id}/users'.replace('{id}', id)); },
    findProjectRequisitions: function(id) { return http.get('/projects/{id}/requisitions'.replace('{id}', id)); }
};

export default Project;
