<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('COMPANIES_LABEL')})  | capitalize }}</strong>
    </div>
    <b-input-group v-else>
      <b-select
          :disabled="disabled"
          id="company-input" type="text"
          :state="state"
          :value="content"
          @change="handleInput">
        <b-select-option :value="undefined">{{$t(undefinedMeans) | capitalize}}</b-select-option>
        <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : valueType === 'object' ? item : item._links.self.href">
          {{ $t(item.name) }}
        </b-select-option>
      </b-select>
      <b-input-group-append v-if="allowAdd">
        <b-button variant="primary" @click="addCompany">
          <b-icon-plus-circle></b-icon-plus-circle>
        </b-button>
      </b-input-group-append>
    </b-input-group>


    <b-form-invalid-feedback id="company-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_LABEL')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import Company from "@/company/index";
import Project from "@/project";

export default {
  name: "CompanySingleSelector",
  props: ['state', 'errors', 'allowAdd', 'type', 'projectId', 'disabled', 'undefinedMeans', 'valueType'],
  mixins: [VModel, SendsMessages],
  async mounted() {
    this.items = await this.getCompanies(this.projectId);
  },
  watch: {
    async projectId(val) {
      this.items = await this.getCompanies(val);
      console.log(this.items);
    }
  },
  methods: {
    async getCompanies(projectId) {
      try {
        this.loading = true;
        if(this.type) {
          return this.type === 'DONOR' ? await Project.getDonors(projectId) : await Company.getSuppliers();
        } else {
          return await Company.findAll({size: 1000, filter: {status: true}});
        }
      } catch (e) {
        this.sendError('ERROR_LOADING_CATALOG', {}, e);
      } finally {
        this.loading = false;
      }
    },
    handleInput(val) {
      this.$emit('input', val);
      const test = this.items.find(item => item._links.self.href === val);
      if(test) {
        this.$emit('accounts', test.accounts);
        this.$emit('rfc', test.rfc);
      }
    },
    addCompany() {
      this.$router.push({name: 'COMPANY_REGISTER_TITLE'});
    },
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>

<style scoped>

</style>
